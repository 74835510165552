import React from "react"
import { Link } from "gatsby"

import { Box, Typography, IconButton, Hidden } from "@mui/material"

import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa"

import Layout from "../components/layout"
import Construction from "../images/construction.svg"

const browser = typeof window !== "undefined"
//Rebuild
const NotFound = () => {
  return (
    browser && (
      <Layout>
        <Box
          display="flex"
          flexDirection={{ xs: "column", lg: "row" }}
          justifyContent="space-between"
          flexWrap="wrap"
          gap={15}
        >
          <Box
            display="flex"
            flexDirection="column"
            flex={1}
            gap={4}
            justifyContent="center"
          >
            <Typography variant="h1">Coming soon</Typography>
            <Typography variant="body1">
              Follow us on our social media networks and get notified when the
              page becomes available.
            </Typography>
            <Box display="flex" gap={1}>
              <Link to="https://www.facebook.com/InfoStudiodoo">
                <IconButton
                  aria-label="Facebook Link"
                  sx={{ border: "0.3px solid #9CC2FE" }}
                >
                  <FaFacebookF color="#2E6ACA" size={18} />
                </IconButton>
              </Link>
              <Link to="https://www.linkedin.com/company/info-studio-d-o-o-sarajevo/mycompany/">
                <IconButton
                  aria-label="LinkedIn link"
                  sx={{ border: "0.3px solid #9CC2FE" }}
                >
                  <FaLinkedinIn color="#2E6ACA" size={18} />
                </IconButton>
              </Link>
              <Link to="https://www.instagram.com/infostudio.doo/">
                <IconButton
                  aria-label="Instagram link"
                  sx={{ border: "0.3px solid #9CC2FE" }}
                >
                  <FaInstagram color="#2E6ACA" size={18} />
                </IconButton>
              </Link>
            </Box>
          </Box>
          <Hidden lgDown implementation="css">
            <Box display="flex" flex={1}>
              <img src={Construction} width={600} />
            </Box>
          </Hidden>
        </Box>
      </Layout>
    )
  )
}

export default NotFound
